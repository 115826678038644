<template>
  <div class="collection-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-radio-group
            id="is_show"
            v-model="formData.is_show"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_hidden" label="يوشۇرامسىز ؟" label-for="is_hidden">
          <b-form-radio-group
            id="is_hidden"
            v-model="formData.is_hidden"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-in_home" label="باشبەتتە كۆرۈنەمدۇ ؟" label-for="in_home">
          <b-form-radio-group
            id="in_home"
            v-model="formData.in_home"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_recommend" label="تەۋسىيەلىكمۇ ؟" label-for="is_recommend">
          <b-form-radio-group
            id="is_recommend"
            v-model="formData.is_recommend"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-8" label="توپلام ماۋزوسى" label-for="title" >
          <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="توپلام ماۋزوسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="title-live-feedback">
            توپلام ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-title-color" label="ماۋزو رەڭ نۇمۇرى" label-for="title-color">
          <b-input-group>
            <b-form-input id="title-color" placeholder=" ماۋزوغا ئىشلىتىدىغان رەڭ" style="direction: ltr" v-model="formData.title_color" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.title_color || ''" @change="changeTitleColor" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-background" label="دېكوراتسىيە رەڭگى " label-for="background">
          <b-input-group>
            <b-form-input id="title-color" placeholder="توپلامنىڭ ئارقا كۆرۈنمە رەڭ نۇمۇرى" style="direction: ltr" v-model="formData.background" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.background || ''" @change="changeBackground" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-9" label="توپلام تەرتىبى" label-for="sort" >
          <b-form-input style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="توپلام تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group description="ئادەتتىكى كىنو رەسىملىرىگە ئوخشاش تىك ھالەتتە رەسىم چىقارسىڭىز بولىدۇ" :state="validateState('cover_image')" aria-describedby="target-live-feedback-cover_image" id="input-group-cover_image" :label="`ئالبوم رەسىم`" label-for="cover_image">
          <image-uploader v-model="formData.cover_image" :upload-form="{strategy: 'collection_cover_image'}" />
          <b-form-invalid-feedback id="target-live-feedback-cover_image">
            توپلام ئۈچۈن ئالبوم رەسىم تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3">
        <b-form-group description="ئېلان رەسىمىگە ئوخشاش كەڭرى رەسىم چىقىرىڭ، كەڭلىكنى 750 پېكسىلنى بىرلىك قىلسىڭىز بولىدۇ" :state="validateState('collection_banner')" aria-describedby="target-live-feedback-collection_banner" id="input-group-collection_banner" :label="`پىلاكات رەسىم`" label-for="collection_banner">
          <image-uploader v-model="formData.collection_banner" :upload-form="{strategy: 'collection_banner_image'}" />
          <b-form-invalid-feedback id="target-live-feedback-collection_banner">
            توپلام ئۈچۈن پىلاكات رەسىم تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <divider title="كىنولار" />
    <b-row class="mt-3">
      <b-col md="1" cols="6" class="mb-2" v-for="(item, index) in formData.films" :key="item.id" v-dragging="{ item: item, list: formData.films, group: 'film' }">
        <film-item @changed="onChangeFilmItem" :index="index" :item="item" />
      </b-col>
      <b-col md="1" cols="4" class="mb-4 d-flex align-items-center justify-content-center">
        <b-button variant="primary" @click="onClickSearchFilmBtn"><i class="fa fa-search"></i> كىنو تاللاش </b-button>
      </b-col>
    </b-row>
    <search-film-modal v-model="filmModalState" @changed="onChangeFilm" />
  </div>
</template>
<script>
  import "@/common/plugins/vue.dnd";
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import Divider from "@v@/partials/widgets/Divider";
  import searchFilmModal from "@v@/components/modals/searchFilmModal";
  import FilmItem from "@v@/components/items/FilmItem";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import "@c@/plugins/vue-color-picker";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import collectionMixin from "@m@/collection.mixin";

  export default {
    name: "CollectionForm",
    mixins: [ collectionMixin, formBusyMixin, validateStateMixin, validationMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      },
      modelId: {
        type: [String, Number],
      },
    },
    validations: {
      formData: {
        title:                { required },
        cover_image:          { required },
        collection_banner:    { required },
      }
    },
    components: { ImageUploader, Divider, searchFilmModal, FilmItem },
    computed: {},
    created() {
      if (this.action === 'edit'){
        this.getCollection(this.modelId)
          .then(({ data: collection })=>{
            this.formData = collection;
          })
      }
    },
    data(){
      return {
        yesOrNot: [
          { text: 'شۇنداق', value: true },
          { text: 'ياق', value: false },
        ],
        formData: {
          title: '',
          title_color: '',
          background: '',
          cover_image: '',
          collection_banner: '',
          is_hidden: false,
          in_home: false,
          is_show: true,
          is_recommend: false,
          sort: null,
          films: []
        },
        filmModalState: false
      };
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning({
            message: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ'
          });
          return;
        }
        if (this.action === 'create'){
          this.storeCollection(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateCollection(this.formData);
        }
      },
      changeTitleColor(e){
        this.formData.title_color = e;
      },
      changeBackground(e){
        this.formData.background = e;
      },
      setFilmModalState(filmModalState = false){
        this.filmModalState = filmModalState;
      },
      onClickSearchFilmBtn(){
        this.setFilmModalState(true);
      },
      onChangeFilm(e){
        let { films } = this.formData;
        films.push(e.film);
      },
      onChangeFilmItem(e){
        let { index, film } = e;
        let { films } = this.formData;
        films.splice(index, 1);
      }
    }
  }
</script>
